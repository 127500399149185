@mixin themify ($themes: $themes) {
  @each $theme, $map in $themes {
    .#{$theme} & {
      // Define theme color
      $theme-map : (
      ) !global;
      
      @each $key, $submap in $map {
        $value: map-fetch($themes, $theme '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      } 
      
      @content;
      
      // reset theme color to null
      $theme-map: null !global;
    }
  }
}

@mixin icon() {
  @include themify() {
    color: getThemifyVariable('icon-color');
    & g, & polygon, & path {
      opacity: 1;
      fill: getThemifyVariable('icon-color');
    }
    &:hover {
      color: getThemifyVariable('icon-hover-color');
      & g, & polygon, & path {
        opacity: 1;
        fill: getThemifyVariable('icon-hover-color');
      }
    }
  }
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}