// brown: #6C4D13
// black: #333
// blue: #0F9DD7
// pink: #D9328F
// gray: #999999
// dark blue: #318094
// white: #fdfdfd

//numbers
//light gray: #f4f4f4
//dark gray: #b5b5b5

$p5-dark-lightbrown: #A67F59;
$p5-light-green: #42F48F;
$p5-dark-black: #1C1C1C;
$p5-dark-pink: #DE4A9B;
$p5-dark-gray: #9B9B9B;
$p5-dark-lightblue: #0F9DD7;
$p5-dark-darkblue: #318094;
$p5-dark-white: #FDFDFD;
$p5-dark-orange: #EE9900;
$p5-dark-lightgray: #E0D7D1;
$p5-dark-darkgray: #666666;
$p5-dark-green: #58a10b;
$p5-dark-goldbrown: #b58318;

$p5-dark-gutter: #f4f4f4;
$p5-dark-number: #b5b5b5;
$p5-dark-selected: rgba(45, 123, 182, 25);
$p5-dark-activeline: rgb(207, 207, 207);

$p5-dark-error: #df3a3d;

.cm-s-p5-dark {
  background-color: $p5-dark-black;
  color: $p5-dark-white;
}

.cm-s-p5-dark span.cm-comment {
  color: $p5-dark-gray;
}

.cm-s-p5-dark span.cm-def {
  color: $p5-dark-lightblue;
}

.cm-s-p5-dark span.cm-string {
  color: $p5-dark-green;
}

.cm-s-p5-dark span.cm-string-2 {
  color: $p5-dark-orange;
}

.cm-s-p5-dark span.cm-number {
  color: $p5-dark-white;
}

.cm-s-p5-dark span.cm-keyword {
  color: $p5-dark-goldbrown;
}

.cm-s-p5-dark span.cm-variable {
  color: $p5-dark-lightblue;
}

.cm-s-p5-dark span.cm-variable-2 {
  color: $p5-dark-white;
}

.cm-s-p5-dark span.cm-property {
  color: $p5-dark-white;
}

.cm-s-p5-dark span.cm-atom {
  color: $p5-dark-pink;
}

.cm-s-p5-dark span.cm-operator {
  color: $p5-dark-white;
}

.cm-s-p5-dark .cm-linenumber {
  color: $p5-dark-number;
}

.cm-s-p5-dark div.CodeMirror-selected {
  background-color: $p5-dark-selected;
}

.cm-s-p5-dark .CodeMirror-activeline-background {
  background-color: $dark;
}

.cm-s-p5-dark .CodeMirror-activeline-gutter {
  background-color: $dark;
  border-right: 1px solid $middle-dark;
}

.cm-s-p5-dark span.CodeMirror-matchingbracket {
  outline: 1px solid $p5-dark-gray;
  outline-offset: 1px;
  color: $p5-dark-white !important;
}

.cm-s-p5-dark span.cm-qualifier {
  color: $p5-dark-lightblue;
}

.cm-s-p5-dark span.cm-tag {
  color: $p5-dark-pink;
}

.cm-s-p5-dark span.cm-error {
  color: $p5-dark-error;
}

.cm-s-p5-dark span.cm-builtin {
  color: $p5-dark-lightblue;
  font-weight: bold;
}

.cm-s-p5-dark span.cm-attribute {
  color: $p5-dark-lightblue;
}

.cm-s-p5-dark .cm-p5-function {
  color: $p5-dark-lightblue;
  font-weight: bold !important;
}

.cm-s-p5-dark .cm-p5-variable {
  color: $p5-dark-pink;
  font-weight: bold;
}

.cm-s-p5-dark .CodeMirror-foldmarker {
  background-color: white;
  color: #333;
}

.cm-s-p5-dark .CodeMirror-cursor {
  border-left: 1px solid $p5-dark-white;
}

.cm-s-p5-dark .cm-searching {
  background-color: $p5js-pink-opacity;
}

.cm-s-p5-dark .CodeMirror-selectedtext {
  background-color: $medium-dark;
}

