// At some point, I will put in a ~real~ reset, but for now

html, body {
	margin: 0;
	padding: 0;
	min-height: 100%;
	height: 100%;
}

ul, p {
	padding: 0;
	margin: 0;
}

h2, h3 {
	margin: 0;
}

ul {
  list-style: none;
}