$base-font-size: 15;

//colors
$p5js-pink: #ed225d;
$p5js-pink-opacity: #ed225d80;
$p5js-active-pink: #f10046;
$white: #fff;
$black: #000;
$yellow: #F5DC23;
$dodgerblue: #1E90FF;
$p5-contrast-pink: #FFA9D9;

$outline-color: #0F9DD7;

// Grayscale values
$lightest: #FFF; // primary
$lighter: #FBFBFB;

$light: #F0F0F0; // primary
$medium-light: #D9D9D9;
$middle-light: #A6A6A6;

// $middle-gray: #7D7D7D; // primary
$middle-gray: #747474; // primary
$middle-dark: #666;
$medium-dark: #4D4D4D;

$dark: #333; // primary
$darker: #1C1C1C;
$darkest: #000;

$themes: (
  light: (
    logo-color: $p5js-pink,
    logo-background-color: $lightest,
    heavy-text-color: $darker,
    primary-text-color: $dark,
    secondary-text-color: $medium-dark,
    inactive-text-color: $middle-dark,
    modal-button-color: $dark,
    background-color: $lighter,
    button-background-color: $light,
    button-secondary-background-color: $medium-light,
    button-color: $black,
    button-border-color: $middle-light,
    toolbar-button-color: $dark,
    toolbar-button-background-color: $medium-light,
    button-background-hover-color: $p5js-pink,
    button-background-active-color: $p5js-active-pink,
    button-nav-inactive-color: $middle-gray,
    button-hover-color: $lightest,
    button-active-color: $lightest,
    modal-background-color: $light,
    preferences-button-background-color: $medium-light,
    modal-border-color: $middle-light,
    icon-color: $middle-gray,
    icon-hover-color: $darker,
    icon-toast-hover-color: $lightest,
    shadow-color: rgba(0, 0, 0, 0.16),
    console-background-color: $light,
    console-input-background-color: $lightest,
    console-color: $darker,
    console-arrow-color: $middle-gray,
    console-active-arrow-color: #0071AD,
    console-header-background-color: $medium-light,
    console-header-color: $darker,
    ide-border-color: $medium-light,
    editor-gutter-color: $lighter,
    file-hover-color: $light,
    file-selected-color: $medium-light,
    input-text-color: $dark,
    input-background-color: $lightest,
    input-secondary-background-color: $lightest,
    input-selection-text-color: $dark,
    input-selection-background-color: $medium-light,
    input-border-color: $middle-light,
    search-background-color: $lightest,
    search-clear-background-color: $light,
    search-hover-text-color: $lightest,
    search-hover-background-color: $medium-dark,
    keyboard-shortcut-color: $middle-gray,
    nav-hover-color: $p5js-pink,
    nav-border-color: $middle-light,
    error-color: $p5js-pink,
    table-row-stripe-color: $medium-light,
    table-row-stripe-color-alternate: $medium-light,
    codefold-icon-open: url("../../images/triangle-arrow-down.svg"),
    codefold-icon-closed: url("../../images/triangle-arrow-right.svg"),

    table-button-color: $lightest,
    table-button-background-color: $middle-gray,
    table-button-active-color: $lightest,
    table-button-background-active-color: #00A1D3,
    table-button-hover-color: $lightest,
    table-button-background-hover-color: $p5js-pink,

    progress-bar-background-color: $middle-gray,
    progress-bar-active-color: $p5js-active-pink,

    form-title-color: rgba(51, 51, 51, 0.87),
    form-secondary-title-color: $medium-dark,
    form-input-text-color: $dark,
    form-input-placeholder-text-color: $middle-light,
    form-navigation-options-color: $middle-dark,

    hint-background-color: $white,
    hint-text-color: $dark,
    hint-item-border-bottom-color: $white,
    hint-fun-text-color: #0B7CA9,
    hint-var-text-color: #D52889,
    hint-keyword-text-color: #7A5A3A,
    hint-type-text-color: $medium-dark,
    hint-arrow-color: $lightest,
    hint-arrow-background-color: #ed225ddd,
    hint-arrow-background-active-color: $p5js-active-pink,
    hint-arrow-focus-outline-color: $middle-dark,
    hint-no-link-background-color: $medium-light,
    hint-item-hover-background-color: #f4f4f4,
    hint-item-active-text-color: $white,
    hint-item-active-background-color: $middle-gray,
    hint-fun-active-border-bottom-color: #0B7CA9,
    hint-var-active-border-bottom-color: #D52889,
    hint-item-active-type-text-color: $white,
    hint-item-active-outline: none,
    hint-item-active-outline-offset: 0,
    hint-inline-text-color-light: $middle-light,
    hint-inline-text-color: $middle-gray,
  ),
  dark: (
    logo-color: $p5js-pink,
    logo-background-color: $lightest,
    heavy-text-color: $lightest,
    primary-text-color: $lightest,
    modal-button-color: $lightest,
    secondary-text-color: $medium-light,
    inactive-text-color: $middle-light,
    background-color: $darker,
    button-background-color: $dark,
    button-secondary-background-color: $medium-dark,
    button-color: $light,
    button-border-color: $middle-dark,
    toolbar-button-color: $lightest,
    toolbar-button-background-color: $medium-dark,
    button-background-hover-color: $p5js-pink,
    button-background-active-color: $p5js-active-pink,
    button-nav-inactive-color: $middle-light,
    button-hover-color: $lightest,
    button-active-color: $lightest,
    modal-background-color: $dark,
    preferences-button-background-color: $middle-dark,
    modal-border-color: $middle-dark,
    icon-color: $middle-light,
    icon-hover-color: $lightest,
    icon-toast-hover-color: $lightest,
    shadow-color: rgba(0, 0, 0, 0.16),
    console-background-color: $dark,
    console-input-background-color: $darker,
    console-color: $lightest,
    console-arrow-color: $medium-light,
    console-active-arrow-color: #097BB3,
    console-header-background-color: $medium-dark,
    console-header-color: $lightest,
    ide-border-color: $middle-dark,
    editor-gutter-color: $darker,
    file-hover-color: $dark,
    file-selected-color: $medium-dark,
    input-text-color: $lightest,
    input-background-color: $dark,
    input-secondary-background-color: $medium-dark,
    input-selection-text-color: $darkest,
    input-selection-background-color: $lightest,
    input-border-color: $middle-dark,
    search-background-color: $darker,
    search-clear-background-color: $medium-dark,
    search-hover-text-color: $lightest,
    search-hover-background-color: $p5js-pink,
    keyboard-shortcut-color: $middle-light,
    nav-hover-color: $p5js-pink,
    nav-border-color: $middle-dark,
    error-color: $p5js-pink,
    table-row-stripe-color: $dark,
    table-row-stripe-color-alternate: $darker,
    codefold-icon-open: url("../../images/triangle-arrow-down-white.svg?byUrl"),
    codefold-icon-closed: url("../../images/triangle-arrow-right-white.svg?byUrl"),

    table-button-color: $lightest,
    table-button-background-color: $middle-gray,
    table-button-active-color: $lightest,
    table-button-background-active-color: #00A1D3,
    table-button-hover-color: $lightest,
    table-button-background-hover-color: $p5js-pink,

    progress-bar-background-color: $middle-gray,
    progress-bar-active-color: $p5js-active-pink,

    form-title-color: $lightest,
    form-secondary-title-color: $medium-light,
    form-navigation-options-color: $middle-light,

    hint-background-color: $darker,
    hint-text-color: $light,
    hint-item-border-bottom-color: $darker,
    hint-fun-text-color: #0F9DD7,
    hint-var-text-color: #DE4A9B,
    hint-keyword-text-color: #B58318,
    hint-type-text-color: $light,
    hint-arrow-color: $lightest,
    hint-arrow-background-color: #ed225ddd,
    hint-arrow-background-active-color: $p5js-active-pink,
    hint-arrow-focus-outline-color: #cfcfcf,
    hint-no-link-background-color: $medium-dark,
    hint-item-hover-background-color: $medium-dark,
    hint-item-active-text-color: $darker,
    hint-item-active-background-color: #cfcfcf,
    hint-fun-active-border-bottom-color: #0F9DD7,
    hint-var-active-border-bottom-color: #DE4A9B,
    hint-item-active-type-text-color: $darker,
    hint-item-active-outline: none,
    hint-item-active-outline-offset: 0,
    hint-inline-text-color-light: $middle-gray,
    hint-inline-text-color: #cfcfcf,
  ),
  contrast: (
    logo-color: $yellow,
    logo-background-color: $darker,
    heavy-text-color: $yellow,
    primary-text-color: $lightest,
    modal-button-color: $dark,
    secondary-text-color: $lighter,
    inactive-text-color: $light,
    background-color: $darker,
    button-background-color: $dark,
    button-secondary-background-color: $medium-dark,
    button-color: $light,
    button-border-color: $middle-dark,
    toolbar-button-color: $dark,
    toolbar-button-background-color: $medium-light,
    button-background-hover-color: $yellow,
    button-background-active-color: $yellow,
    button-nav-inactive-color: $light,
    button-hover-color: $dark,
    button-active-color: $dark,
    modal-background-color: $dark,
    preferences-button-background-color: $medium-light,
    modal-border-color: $middle-dark,
    icon-color: $medium-light,
    icon-hover-color: $yellow,
    icon-toast-hover-color: $yellow,
    shadow-color: rgba(0, 0, 0, 0.16),
    console-background-color: $dark,
    console-input-background-color: $darker,
    console-color: $lightest,
    console-arrow-color: $lightest,
    console-active-arrow-color: $dodgerblue,
    console-header-background-color: $medium-dark,
    console-header-color: $lightest,
    ide-border-color: $middle-dark,
    editor-gutter-color: $darker,
    file-hover-color: $dark,
    file-selected-color: $medium-dark,
    input-text-color: $lightest,
    input-background-color: $dark,
    input-secondary-background-color: $medium-dark,
    input-selection-text-color: $darkest,
    input-selection-background-color: $lightest,
    input-border-color: $middle-dark,
    search-background-color: $darker,
    search-clear-background-color: $medium-dark,
    search-hover-text-color: $dark,
    search-hover-background-color: $yellow,
    keyboard-shortcut-color: $middle-light,
    nav-hover-color: $yellow,
    nav-border-color: $middle-dark,
    error-color: $p5-contrast-pink,
    table-row-stripe-color: $dark,
    table-row-stripe-color-alternate: $darker,
    codefold-icon-open: url("../../images/triangle-arrow-down-white.svg?byUrl"),
    codefold-icon-closed: url("../../images/triangle-arrow-right-white.svg?byUrl"),

    table-button-color: $dark,
    table-button-background-color: $middle-gray,
    table-button-active-color: $dark,
    table-button-background-active-color: #00FFFF,
    table-button-hover-color: $dark,
    table-button-background-hover-color: $yellow,

    progress-bar-background-color: $middle-gray,
    progress-bar-active-color: $p5js-active-pink,

    form-title-color: $lightest,
    form-secondary-title-color: $medium-light,
    form-navigation-options-color: $middle-light,

    hint-background-color: $darkest,
    hint-text-color: $medium-light,
    hint-item-border-bottom-color: $medium-dark,
    hint-fun-text-color: #00FFFF,
    hint-var-text-color: #FFA9D9,
    hint-keyword-text-color: #F5DC23,
    hint-type-text-color: $middle-light,
    hint-arrow-color: $darker,
    hint-arrow-background-color: #F5DC23DD,
    hint-arrow-background-active-color: #F5DC23,
    hint-arrow-focus-outline-color: $lighter,
    hint-no-link-background-color: $medium-dark,
    hint-item-hover-background-color: $dark,
    hint-item-active-text-color: $lighter,
    hint-item-active-background-color: unset,
    hint-fun-active-border-bottom-color: none,
    hint-var-active-border-bottom-color: none,
    hint-item-active-type-text-color: $lighter,
    hint-item-active-outline: 2px solid $lighter,
    hint-item-active-outline-offset: -2px,
    hint-inline-text-color-light: $middle-gray,
    hint-inline-text-color: #cfcfcf,
  )
);

$toast-background-color: $medium-dark;
$toast-text-color: $lightest;

