@use "sass:math";

* {
	box-sizing: border-box;
}

html,
body {
	font-size: #{$base-font-size}px;
}


body,
input,
textarea {
	@include themify() {
		color: getThemifyVariable("primary-text-color");
	}
}

body,
input,
textarea,
button {
	font-family: Montserrat, sans-serif;
}

.root-app,
.app {
	min-height: 100%;
	height: 100%;
}

a {
	@include themify() {
		@extend %link;
	}
}

input,
button {
	font-size: 1rem;
}

input,
textarea {
	padding: #{math.div(5, $base-font-size)}rem;
	border: 1px solid;
	border-radius: 2px;
	padding: #{math.div(10, $base-font-size)}rem;
	@include themify() {
		color: getThemifyVariable("input-text-color");
		background-color: getThemifyVariable("input-background-color");
		border-color: getThemifyVariable("input-border-color");
	}
}

input::selection,
textarea::selection {
	@include themify() {
		color: getThemifyVariable("input-selection-text-color");
		background-color: getThemifyVariable("input-selection-background-color");
	}
}

button[type="submit"],
input[type="submit"] {
	@include themify() {
		@extend %button;
	}
}

button[type="submit"]:disabled,
input[type="submit"]:disabled {
	cursor: not-allowed;
}

button {
	@include themify() {
		@extend %link;
	}
	background: transparent;
	border: none;
}

h1 {
	font-size: #{math.div(21, $base-font-size)}em;
}

h2 {
	font-size: #{math.div(21, $base-font-size)}em;
}

h3 {
	font-weight: normal;
	font-size: #{math.div(16, $base-font-size)}rem;
}
h4 {
	font-weight: normal;
}
h6 {
	font-weight: normal;
	font-size: #{math.div(12, $base-font-size)}rem;
}
thead {
	text-align: left;
}
th {
	text-align: left;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
	outline: none;
	box-shadow: 0 0 0 1px $outline-color;
}

// screen reader only class
// from https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html#hiding-content-visually
.sr-only:not(:focus):not(:active) {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}
