@use "sass:math";

.CodeMirror-hints {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  list-style: none;

  margin: 0;
  padding: 0;

  box-shadow: 0 0 #{math.div(18, $base-font-size)}rem 0 rgba(0, 0, 0, 0.16);
  border: #{math.div(1, $base-font-size)}rem solid #A6A6A6;

  font-size: 100%;
  font-family: Inconsolata, monospace;

  width: 18rem;
  max-height: 20rem;
  overflow-y: auto;

  transform-origin: top left;

  @include themify() {
    background: getThemifyVariable('hint-background-color');

    .CodeMirror-hint {
      color: getThemifyVariable('hint-text-color');
      border-bottom: #{math.div(1, $base-font-size)}rem solid getThemifyVariable('hint-item-border-bottom-color');
    }

    .hint-name {
      height: 100%;
    }
  
    .fun-name, .obj-name {
      color: getThemifyVariable('hint-fun-text-color');
    }
  
    .var-name, .boolean-name {
      color: getThemifyVariable('hint-var-text-color');
    }

    .keyword-name {
      color: getThemifyVariable('hint-keyword-text-color');
    }
  
    .hint-type {
      color: getThemifyVariable('hint-type-text-color');
      margin-right: #{math.div(10, $base-font-size)}rem;
    }
  
    a {
      color: getThemifyVariable('hint-arrow-color');
      background: getThemifyVariable('hint-arrow-background-color');

      &:hover, &:active, &.focused-hint-link {
        background: getThemifyVariable('hint-arrow-background-active-color');
      }

      &.focused-hint-link {
        outline: #{math.div(3, $base-font-size)}rem solid getThemifyVariable('hint-arrow-focus-outline-color');
        outline-offset: #{math.div(-3, $base-font-size)}rem;
      }
    }

    .no-link-placeholder {
      background: getThemifyVariable('hint-no-link-background-color');
      pointer-events: none;
    }
  
    li.CodeMirror-hint-active:not(.unfocused) {
      background: getThemifyVariable('hint-item-active-background-color');
      outline: getThemifyVariable('hint-item-active-outline');
      outline-offset: getThemifyVariable('hint-item-active-outline-offset');

      // .fun-item {
      //   border-bottom: #{2 / $base-font-size}rem solid getThemifyVariable('hint-fun-active-border-bottom-color');
      // }

      // .var-item {
      //   border-bottom: #{2 / $base-font-size}rem solid getThemifyVariable('hint-var-active-border-bottom-color');
      // }

      .hint-name {
        color: getThemifyVariable('hint-item-active-text-color');
      }

      .fun-name, .obj-name {
        background-color: getThemifyVariable('hint-fun-text-color');
      }
    
      .var-name, .boolean-name {
        background-color: getThemifyVariable('hint-var-text-color');
      }

      .keyword-name {
        background-color: getThemifyVariable('hint-keyword-text-color');
      }
  
      .hint-type, .plain-hint-item {
        color: getThemifyVariable('hint-item-active-type-text-color');
      }
    }
  
    .CodeMirror-hint:hover:not(.CodeMirror-hint-active) {
      background: getThemifyVariable('hint-item-hover-background-color');
    }
  }

  .CodeMirror-hint {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: relative;
    margin: 0;
    padding: 0;
    height: 2rem;
    white-space: pre;
    cursor: pointer;

    &:has(.focused-hint-link) {
      z-index: 999;
    }

    &:only-child, &:last-child {
      border-bottom: none !important;
    }

    p {
      display: flex;
      width: 100%;
      height: 100%;
    }
  
    .hint-name, .plain-hint-item {
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      width: min-content;
      font-size: 1.2rem;
      line-height: 100%;
      font-weight: bold;
    }
  
    .hint-type {
      margin: 0.5rem 2.4rem 0.5rem auto;
      font-size: 1rem;
      line-height: 100%;
      font-weight: normal;
    }

    .hint-hidden {
      @extend %hidden-element;
    }
  
    a, .no-link-placeholder {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: calc(2rem - #{math.div(1, $base-font-size)}rem);
      margin: 0;
      padding-top: 0.4rem;
      font-size: 1.2rem;
      line-height: 100%;
      text-align: center;
      outline: none;
      z-index: 1;
    }
  
    a:focus, a:active {
      outline: 0;
    }
  }
}

// Inline hinter
.CodeMirror-widget {
  line-height: inherit;

  @include themify() {
    .autocomplete-inline-hinter {
      // make the border left look like a cursor and animate like a cursor
      // border-left: #{1.2 / $base-font-size}rem solid getThemifyVariable(hint-inline-text-color);
      // animation: inline-hint-caret-blink 1s step-end infinite;
      pointer-events: none;

      .inline-hinter-suggestion {
        color: getThemifyVariable(hint-inline-text-color);
        font-style: italic;
      }

      .inline-hinter-suggestion-light {
        color: getThemifyVariable(hint-inline-text-color-light);
        font-style: italic;
      }
    }
  }
}

@keyframes inline-hint-caret-blink {
  50% { border-color: transparent; }
}
