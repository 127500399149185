// brown: #6C4D13
// black: #333
// blue: #0F9DD7
// pink: #D9328F
// gray: #999999
// dark blue: #318094
// white: #fdfdfd

//numbers
//light gray: #f4f4f4
//dark gray: #b5b5b5

@use "sass:math";

$p5-contrast-black: #1C1C1C;
$p5-contrast-gray: #A0A0A0;
$p5-contrast-white: #FDFDFD;
$p5-contrast-darkgray: #333333;
$p5-contrast-lightgray: #C1C1C1;
$p5-contrast-blue: #00FFFF;
$p5-contrast-green: #2DE9B6;
$p5-contrast-yellow: #F5DC23;
$p5-contrast-orange: #FFA95D;
$p5-contrast-pink: #FFA9D9;

$p5-contrast-gutter: #454545;
$p5-contrast-number: #FDFDFD;
$p5-contrast-selected: rgba(45, 123, 182, 25);
$p5-contrast-activeline: #999999;

.cm-s-p5-contrast {
  background-color: $p5-contrast-black;
  color: $p5-contrast-white;
}

.cm-s-p5-contrast span .cm-comment {
  color: $p5-contrast-lightgray;
}

.cm-s-p5-contrast span .cm-def {
  color: $p5-contrast-blue;
}

.cm-s-p5-contrast span .cm-string {
  color: $p5-contrast-green;
}

.cm-s-p5-contrast span .cm-string-2 {
  color: $p5-contrast-green;
}

.cm-s-p5-contrast span .cm-number {
  color: $p5-contrast-pink;
}

.cm-s-p5-contrast span .cm-keyword {
  color: $p5-contrast-yellow;
}

.cm-s-p5-contrast span .cm-variable {
  color: $p5-contrast-white;
}

.cm-s-p5-contrast span .cm-variable-2 {
  color: $p5-contrast-white;
}

.cm-s-p5-contrast span .cm-property {
  color: $p5-contrast-white;
}

.cm-s-p5-contrast span .cm-atom {
  color: $p5-contrast-pink;
}

.cm-s-p5-contrast span .cm-operator {
  color: $p5-contrast-lightgray;
}

.cm-s-p5-contrast .cm-linenumber {
  color: $p5-contrast-number;
}

.cm-s-p5-contrast div .CodeMirror-selected {
  background-color: $p5-contrast-selected;
}

.cm-s-p5-contrast .CodeMirror-activeline-background {
  background-color: $dark;
}

.cm-s-p5-contrast .CodeMirror-activeline-gutter {
  background-color: $dark;
  border-right: 1px solid $middle-dark;
}

.cm-s-p5-contrast .cm-error {
  color: #f00;
}

.cm-s-p5-contrast span .CodeMirror-matchingbracket {
  outline: 1px solid $p5-contrast-lightgray;
  outline-offset: 1px;
  color: $p5-contrast-white !important;
}

.cm-s-p5-contrast span .cm-qualifier {
  color: $p5-contrast-yellow;
}

.cm-s-p5-contrast span .cm-tag {
  color: $p5-contrast-orange;
}

.cm-s-p5-contrast span .cm-builtin {
  color: $p5-contrast-yellow;
}

.cm-s-p5-contrast span .cm-attribute {
  color: $p5-contrast-white;
}

.cm-s-p5-contrast .cm-p5-function {
  color: $p5-contrast-blue;
}

.cm-s-p5-contrast .cm-p5-variable {
  color: $p5-contrast-pink;
  font-weight: bold;
}

.cm-s-p5-contrast .CodeMirror-foldmarker {
  background-color: white;
  color: #333;
}

.cm-s-p5-contrast .CodeMirror-cursor {
  border-left: 1px solid $p5-contrast-white;
}

.cm-s-p5-contrast .cm-searching {
  // background-color: $p5js-pink-opacity;
  background-color: $medium-dark;
}

.cm-s-p5-contrast .cm-searching.CodeMirror-selectedtext {
  // background-color: $medium-dark;
  outline: #{math.div(1, $base-font-size)}rem solid $p5-contrast-white;
}
